/* eslint-disable react-hooks/exhaustive-deps */
// DataDisplay.js
import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { loginRequest } from "../Auth/msalConfig";
import { useMsal } from "@azure/msal-react";

// import MenuIcon from "@mui/icons-material/Menu";

import { Box, Select, MenuItem, FormControl, Drawer } from "@mui/material";

import StoreFilter from "./StoreFilter";
import DataChart from "./DataChart";

function DataDisplay({ toggleDrawer, openDrawer }) {
  const { instance, accounts } = useMsal();
  const [rowData, setRowData] = useState([]);
  const [, setError] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [selectedColumn1, setSelectedColumn1] = useState("projectCode");
  const [selectedColumn2, setSelectedColumn2] = useState("afwijking");
  const [selectedDataForChart, setSelectedDataForChart] = useState(null);
  const [sortedData, setSortedData] = useState([]);
  const [tableLoaded, setTableLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Use the active account to acquire a token
        const tokenResponse = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0], // Use the first available account, adjust if necessary
        });

        const response = await fetch(
          "https://3pprijzenapi.azurewebsites.net/api/projecten/raminggunning",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.accessToken}`,
            },
          },
        );
        if (response.ok) {
          const jsonData = await response.json();
          // console.log("API Data: ", jsonData);
          // setRowData(jsonData);
          const updatedData = jsonData.map((item) => {
            // const projectCodeParts = item.projectCode.split('.'); // Split the projectCode by space
            const projectCodeParts = item.projectCode.split(" "); // Split the projectCode by space
            const projectCode = projectCodeParts[0]; // Get the first part of the split
            const updatedProjectCode = projectCode; // Concatenate projectCode and perceel
            const oppervlakte = item.oppervlakte;

            const formattedDateGunning = formatDate(item.datumGunning);
            const formattedDateRaming = formatDate(item.datumCreatieRaming);
            const formattedDateNazicht = formatDate(item.datumNazicht);

            // Calculate "Prijs/m² Raming"
            const prijsPerM2Raming =
              oppervlakte === 0
                ? 0
                : parseFloat((item.totaalRaming / oppervlakte).toFixed(2));

            // Calculate "Prijs/m² Gunning"
            const prijsPerM2Gunning =
              oppervlakte === 0
                ? 0
                : parseFloat((item.totaalGunning / oppervlakte).toFixed(2));
            const afwijking =
              item.afwijking === -100 || item.afwijking === 100
                ? item.totaalRaming === 0 ||
                  item.totaalRaming === null ||
                  item.totaalGunning === 0 ||
                  item.totaalGunning === null
                  ? 0 // Set "afwijking" to 0 if the conditions are met
                  : item.afwijking // Otherwise, keep the original value
                : item.afwijking;
            return {
              ...item,
              projectCode: updatedProjectCode, // Update the projectCode field
              afwijking: afwijking,
              datumGunning: formattedDateGunning,
              datumCreatieRaming: formattedDateRaming,
              datumNazicht: formattedDateNazicht,

              prijsPerM2Raming: prijsPerM2Raming,
              prijsPerM2Gunning: prijsPerM2Gunning,
            };
          });

          // console.log("UpdatedData: ", updatedData);

          setRowData(updatedData);
          const allData = updatedData.map((item) => ({
            [selectedColumn1]: item[selectedColumn1],
            [selectedColumn2]: item[selectedColumn2],
            perceel: item.perceel,
            // Add more fields as needed
          }));
          allData.sort((a, b) => {
            // Assuming `datum` is a date field, you can compare them like this:
            const dateA = new Date(a.datumGunning);
            const dateB = new Date(b.datumGunning);

            // First, compare by projectCode in ascending order
            if (a.projectCode < b.projectCode) {
              return -1;
            }
            if (a.projectCode > b.projectCode) {
              return 1;
            }

            // If projectCode is the same, then compare by datumGunning in descending order
            return dateB - dateA;
          });
          const delay = 1000; // You can adjust the delay time
          setTimeout(() => {
            setSelectedDataForChart(allData);
            setTableLoaded(true);
          }, delay);
          // setSelectedDataForChart(selectedSortedData);
        } else {
          setError("Failed to fetch data.");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  const sectorswithNull = rowData.flatMap((item) => item.sectoren);
  const perceel = rowData.flatMap((item) => item.perceel);

  const teamwithNull = rowData.flatMap((item) => item.team);
  const sectors = sectorswithNull.filter((item) => item !== null);
  const team = teamwithNull.filter((item) => item !== null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Customize the format as needed
  };

  const gridOptions = {
    columnDefs: [
      { headerName: "Project Code", field: "projectCode" },
      { headerName: "Project Description", field: "projectOmschrijving" },
      {
        headerName: "Total Gunning",
        field: "totaalGunning",
        valueFormatter: (params) =>
          params.value
            ? `${params.value.toLocaleString("nl-BE", {
                style: "currency",
                currency: "EUR",
              })}`
            : "",
      },
      {
        headerName: "Total Raming",
        field: "totaalRaming",
        valueFormatter: (params) =>
          params.value
            ? `${params.value.toLocaleString("nl-BE", {
                style: "currency",
                currency: "EUR",
              })}`
            : "",
      },
      {
        headerName: "Afwijking",
        field: "afwijking",
        cellStyle: (params) => {
          const afwijking = parseFloat(params.value);

          if (afwijking >= -15 && afwijking <= 15) {
            return { backgroundColor: "lightgreen" }; // Tussen -15 en 15
          } else if (
            (afwijking >= -1000 && afwijking < -15) ||
            (afwijking > 15 && afwijking <= 1000)
          ) {
            return { backgroundColor: "#FF6961" }; // Tussen -100 en -15 of 15 en 100
          } else {
            // Als de afwijking buiten alle bovengenoemde bereiken valt
            return { backgroundColor: "transparent" }; // Of een andere standaardachtergrondkleur
          }
        },
        valueFormatter: (params) => {
          // Format the percentage with two decimal places and a percentage symbol
          return parseFloat(params.value).toFixed(2) + "%";
        },
      },
      { headerName: "Perceel", field: "perceel" },
      { headerName: "Oppervlakte", field: "oppervlakte" },
      {
        headerName: "Datum Raming",
        field: "datumCreatieRaming",
        // valueFormatter: (params) => formatDate(params.value),
      },
      {
        headerName: "Datum Gunning",
        field: "datumGunning",
        // valueFormatter: (params) => formatDate(params.value),
      },
      {
        headerName: "Datum Nazicht",
        field: "datumNazicht",
        // valueFormatter: (params) => formatDate(params.value),
      },
      {
        headerName: "Prijs/m² Gunning",
        field: "prijsPerM2Gunning",
        valueFormatter: (params) =>
          params.value
            ? `${params.value.toLocaleString("nl-BE", {
                style: "currency",
                currency: "EUR",
              })}`
            : "",
      },
      {
        headerName: "Prijs/m² Raming",
        field: "prijsPerM2Raming",
        valueFormatter: (params) =>
          params.value
            ? `${params.value.toLocaleString("nl-BE", {
                style: "currency",
                currency: "EUR",
              })}`
            : "",
      },
      { headerName: "Sector", field: "sectoren" },
      {
        headerName: "Team",
        field: "team",
        valueFormatter: (params) => params.value || "",
      },
    ],
    defaultColDef: {
      resizable: true,
      sortable: true,
    },
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const applyExternalFilter = (filters) => {
    if (gridApi) {
      let filteredData = [...rowData]; // Create a copy of the original data to avoid modifying it directly

      //------------------------------------------------ DATUM FILTER RAMING ------------------------------------------------

      if (filters.startDateRaming || filters.endDateRaming) {
        const filterstartDateRaming = filters.startDateRaming
          ? new Date(filters.startDateRaming)
          : null;
        const filterendDateRaming = filters.endDateRaming
          ? new Date(filters.endDateRaming)
          : null;

        filteredData = filteredData.filter((row) => {
          const rowDate = new Date(row.datumCreatieRaming);
          return (
            (!filterstartDateRaming || rowDate >= filterstartDateRaming) &&
            (!filterendDateRaming || rowDate <= filterendDateRaming)
          );
        });
      }
      //------------------------------------------------ DATUM FILTER GUNNING ------------------------------------------------

      if (filters.startDateGunning || filters.endDateGunning) {
        const filterstartDateGunning = filters.startDateGunning
          ? new Date(filters.startDateGunning)
          : null;
        const filterendDateGunning = filters.endDateGunning
          ? new Date(filters.endDateGunning)
          : null;

        filteredData = filteredData.filter((row) => {
          const rowDate = new Date(row.datumGunning);
          return (
            (!filterstartDateGunning || rowDate >= filterstartDateGunning) &&
            (!filterendDateGunning || rowDate <= filterendDateGunning)
          );
        });
      }
      //------------------------------------------------ DATUM FILTER NAZICHT ------------------------------------------------

      if (filters.startDateNazicht || filters.endDateNazicht) {
        const filterstartDateNazicht = filters.startDateNazicht
          ? new Date(filters.startDateNazicht)
          : null;
        const filterendDateNazicht = filters.endDateNazicht
          ? new Date(filters.endDateNazicht)
          : null;

        filteredData = filteredData.filter((row) => {
          const rowDate = new Date(row.datumNazicht);
          return (
            (!filterstartDateNazicht || rowDate >= filterstartDateNazicht) &&
            (!filterendDateNazicht || rowDate <= filterendDateNazicht)
          );
        });
      }

      //------------------------------------------------ DATUM FILTER CODE ------------------------------------------------

      if (
        filters.searchQueryCode !== undefined &&
        filters.searchQueryCode !== ""
      ) {
        const query = filters.searchQueryCode.toLowerCase();
        filteredData = filteredData.filter((row) => {
          // Check if row.projectCode is defined before calling toLowerCase()
          return (
            row.projectCode && row.projectCode.toLowerCase().includes(query)
          );
        });
      }

      //------------------------------------------------ DATUM FILTER DESCRIPTION TODO ------------------------------------------------

      if (
        filters.searchQueryDesc !== undefined &&
        filters.searchQueryDesc !== ""
      ) {
        const query = filters.searchQueryDesc.toLowerCase();
        filteredData = filteredData.filter((row) => {
          // Check if row.projectCode is defined before calling toLowerCase()
          return (
            row.projectOmschrijving &&
            row.projectOmschrijving.toLowerCase().includes(query)
          );
        });
      }

      //------------------------------------------------ DATUM FILTER SECTOR ------------------------------------------------

      if (filters.selectedSector && filters.selectedSector.length > 0) {
        // Check if the array is not empty
        filteredData = filteredData.filter((row) => {
          return (
            row.sectoren &&
            row.sectoren.some((sector) =>
              filters.selectedSector.includes(sector),
            )
          );
        });
      }

      //------------------------------------------------ DATUM FILTER PERCEEL ------------------------------------------------

      if (filters.selectedPerceel && filters.selectedPerceel.length > 0) {
        // Check if the array is not empty
        filteredData = filteredData.filter((row) => {
          return filters.selectedPerceel.includes(row.perceel);
        });
      }

      //------------------------------------------------ DATUM FILTER TEAM ------------------------------------------------

      if (filters.selectedTeam && filters.selectedTeam.length > 0) {
        // Check if the array is not empty
        filteredData = filteredData.filter((row) => {
          return filters.selectedTeam.includes(row.team);
        });
      }

      //------------------------------------------------ DATUM FILTER OPPERVLAKTE ------------------------------------------------

      if (filters.minOppervlakte !== "" || filters.maxOppervlakte !== "") {
        filteredData = filteredData.filter((row) => {
          const oppervlakte = row.oppervlakte;
          const minOppervlakte =
            filters.minOppervlakte !== ""
              ? parseFloat(filters.minOppervlakte)
              : Number.NEGATIVE_INFINITY;
          const maxOppervlakte =
            filters.maxOppervlakte !== ""
              ? parseFloat(filters.maxOppervlakte)
              : Number.POSITIVE_INFINITY;

          return oppervlakte >= minOppervlakte && oppervlakte <= maxOppervlakte;
        });
      }

      //------------------------------------------------ DATUM FILTER AFWIJKING ------------------------------------------------

      if (filters.minAfwijking !== "" || filters.maxAfwijking !== "") {
        filteredData = filteredData.filter((row) => {
          const oppervlakte = row.afwijking;
          const minAfwijking =
            filters.minAfwijking !== ""
              ? parseFloat(filters.minAfwijking)
              : Number.NEGATIVE_INFINITY;
          const maxAfwijking =
            filters.maxAfwijking !== ""
              ? parseFloat(filters.maxAfwijking)
              : Number.POSITIVE_INFINITY;

          return oppervlakte >= minAfwijking && oppervlakte <= maxAfwijking;
        });
      }

      //------------------------------------------------ DATUM FILTER RAMING PRIJS ------------------------------------------------

      if (filters.minRaming !== "") {
        filteredData = filteredData.filter((row) => {
          return row.totaalRaming >= parseFloat(filters.minRaming);
        });
      }

      if (filters.maxRaming !== "") {
        filteredData = filteredData.filter((row) => {
          return row.totaalRaming <= parseFloat(filters.maxRaming);
        });
      }

      //------------------------------------------------ DATUM FILTER GUNNING PRIJS ------------------------------------------------

      if (filters.minGunning !== "") {
        filteredData = filteredData.filter((row) => {
          return row.totaalGunning >= parseFloat(filters.minGunning);
        });
      }

      if (filters.maxGunning !== "") {
        filteredData = filteredData.filter((row) => {
          return row.totaalGunning <= parseFloat(filters.maxGunning);
        });
      }

      //------------------------------------------------ DATUM FILTER RAMING PRIJS m² ------------------------------------------------

      if (filters.minPrijsRaming !== "") {
        filteredData = filteredData.filter((row) => {
          return row.prijsPerM2Raming >= parseFloat(filters.minPrijsRaming);
        });
      }

      if (filters.maxPrijsRaming !== "") {
        filteredData = filteredData.filter((row) => {
          return row.prijsPerM2Raming <= parseFloat(filters.maxPrijsRaming);
        });
      }

      //------------------------------------------------ DATUM FILTER GUNNING PRIJS m² ------------------------------------------------

      if (filters.minPrijsGunning !== "") {
        filteredData = filteredData.filter((row) => {
          return row.prijsPerM2Gunning >= parseFloat(filters.minPrijsGunning);
        });
      }

      if (filters.maxPrijsGunning !== "") {
        filteredData = filteredData.filter((row) => {
          return row.prijsPerM2Gunning <= parseFloat(filters.maxPrijsGunning);
        });
      }
      console.log("filterdata", filteredData);
      if (
        filters.startDateRaming === "" &&
        filters.endDateRaming === "" &&
        filters.startDateGunning === "" &&
        filters.endDateGunning === "" &&
        filters.startDateNazicht === "" &&
        filters.endDateNazicht === "" &&
        filters.selectedSector === "" &&
        filters.selectedPerceel === "" &&
        filters.selectedTeam === "" &&
        filters.minOppervlakte === "" &&
        filters.maxOppervlakte === "" &&
        filters.minAfwijking === "" &&
        filters.maxAfwijking === "" &&
        filters.minRaming === "" &&
        filters.maxRaming === "" &&
        filters.minGunning === "" &&
        filters.maxGunning === "" &&
        filters.minPrijsRaming === "" &&
        filters.minPrijsGunning === "" &&
        filters.maxPrijsRaming === "" &&
        filters.maxPrijsGunning === ""
      ) {
        filteredData = [...rowData];
      }

      gridApi.setRowData(filteredData);

      // setSelectedDataForChart(selectedSortedData);
      onSortChanged();
    }
  };

  const handleColumn1Change = (event) => {
    setSelectedColumn1(event.target.value);
  };

  const handleColumn2Change = (event) => {
    setSelectedColumn2(event.target.value);
  };
  const formatCurrency = (value) => {
    return value.toLocaleString("nl-BE", {
      style: "currency",
      currency: "EUR",
    });
  };

  const onSortChanged = () => {
    // console.log(gridApi)
    if (gridApi) {
      const sortedRows = [];

      //--------------------------------------------------FILTER ON TABEL--------------------------------------------------------------------

      // gridApi.forEachNodeAfterFilterAndSort((node) => {
      //   sortedRows.push(node.data);
      // });

      gridApi.forEachNodeAfterFilter((node) => {
        sortedRows.push(node.data);
      });
      // console.log(sortedRows);

      //--------------------------------------------------FILTER ON DATUM Needs to be fixed--------------------------------------------------------------------

      // sortedRows.sort((a, b) => {
      //   const dateA = new Date(a.datumGunning);
      //   const dateB = new Date(b.datumGunning);
      //   return dateA - dateB;
      // });

      //--------------------------------------------------FILTER ON ProjectCode Needs to be fixed--------------------------------------------------------------------

      // sortedRows.sort((a, b) => {

      //   if (a.projectCode < b.projectCode) {
      //     return -1;
      //   }
      //   if (a.projectCode > b.projectCode) {
      //     return 1;
      //   }
      //   return 0;
      // });

      //--------------------------------------------------LIJST SORTED DATA--------------------------------------------------------------------

      sortedRows.sort((a, b) => {
        // Assuming `datum` is a date field, you can compare them like this:
        const dateA = new Date(a.datumGunning);
        const dateB = new Date(b.datumGunning);

        // First, compare by projectCode in ascending order
        if (a.projectCode < b.projectCode) {
          return -1;
        }
        if (a.projectCode > b.projectCode) {
          return 1;
        }

        // If projectCode is the same, then compare by datumGunning in descending order
        return dateB - dateA;
      });

      setSortedData(sortedRows);
      // console.table(sortedRows);

      //-------------------------------------------------- Bekijken wat dit is --------------------------------------------------------------------
      if (selectedColumn1 && selectedColumn2) {
        const selectedSortedData = sortedRows.map((item) => {
          const formattedItem = {
            [selectedColumn1]: item[selectedColumn1],
            [selectedColumn2]: item[selectedColumn2],
            perceel: item.perceel,
          };

          // Format the 'datum' field if it's selected
          if (selectedColumn1 === "datum") {
            formattedItem[selectedColumn1] = formatDate(item[selectedColumn1]);
          }
          if (selectedColumn2 === "datum") {
            formattedItem[selectedColumn2] = formatDate(item[selectedColumn2]);
          }

          // Format the 'totaalraming' field if it's selected
          if (selectedColumn1 === "totaalraming") {
            formattedItem[selectedColumn1] = formatCurrency(
              item[selectedColumn1],
            );
          }
          if (selectedColumn2 === "totaalraming") {
            formattedItem[selectedColumn2] = formatCurrency(
              item[selectedColumn2],
            );
          }

          // Format the 'totaalgunning' field if it's selected
          if (selectedColumn1 === "totaalgunning") {
            formattedItem[selectedColumn1] = formatCurrency(
              item[selectedColumn1],
            );
          }
          if (selectedColumn2 === "totaalgunning") {
            formattedItem[selectedColumn2] = formatCurrency(
              item[selectedColumn2],
            );
          }

          // console.table(formattedItem);
          return formattedItem;
        });

        //--------------------------------------------------LIJST CHART DATA--------------------------------------------------------------------

        setSelectedDataForChart(selectedSortedData);
        // console.log(selectedSortedData);
      }
    }
  };

  const exportCSV = () => {
    if (gridApi) {
      const params = {
        fileName: "exported_data.csv",
      };

      gridApi.exportDataAsCsv(params);
    }
  };

  const maxMinAfwijking = rowData.reduce(
    (acc, current) => {
      const afwijking = current.afwijking;

      // Check if afwijking is greater than the current maximum
      if (afwijking > acc.max) {
        acc.max = afwijking;
      }

      // Check if afwijking is less than the current minimum
      if (afwijking < acc.min) {
        acc.min = afwijking;
      }

      return acc;
    },
    { max: -Infinity, min: Infinity },
  );

  const maxAfwijking = maxMinAfwijking.max;
  const minAfwijking = maxMinAfwijking.min;

  const maxMinOppervlakte = rowData.reduce(
    (acc, current) => {
      const oppervlakte = current.oppervlakte;

      // Check if oppervlakte is greater than the current maximum
      if (oppervlakte > acc.max) {
        acc.max = oppervlakte;
      }

      // Check if oppervlakte is less than the current minimum
      if (oppervlakte < acc.min) {
        acc.min = oppervlakte;
      }

      return acc;
    },
    { max: -Infinity, min: Infinity },
  );

  const maxOppervlakte = maxMinOppervlakte.max;
  const minOppervlakte = maxMinOppervlakte.min;

  // const [openDrawer, setOpenDrawer] = useState(false);
  const clearFilters = () => {
    const filteredData = [...rowData];
    gridApi.setRowData(filteredData);
    // setSelectedDataForChart(filteredData);
    const allData = filteredData.map((item) => ({
      [selectedColumn1]: item[selectedColumn1],
      [selectedColumn2]: item[selectedColumn2],
      perceel: item.perceel,
      // Add more fields as needed
    }));
    allData.sort((a, b) => {
      // Assuming `datum` is a date field, you can compare them like this:
      const dateA = new Date(a.datumGunning);
      const dateB = new Date(b.datumGunning);

      // First, compare by projectCode in ascending order
      if (a.projectCode < b.projectCode) {
        return -1;
      }
      if (a.projectCode > b.projectCode) {
        return 1;
      }

      // If projectCode is the same, then compare by datumGunning in descending order
      return dateB - dateA;
    });
    const delay = 1000; // You can adjust the delay time
    setTimeout(() => {
      setSelectedDataForChart(allData);
      setTableLoaded(true);
    }, delay);
  };

  return (
    <Box height={"100vh"} paddingLeft={40}>
      {tableLoaded ? <DataChart selectedData={selectedDataForChart} /> : null}

      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={toggleDrawer}
        variant="permanent"
      >
        {/* <Collapse in={showFilters}> */}
        <div style={{ width: 300, margin: "10px" }}>
          <FormControl style={{ marginBottom: "10px" }}>
            {/* <InputLabel>Select Column 1</InputLabel> */}
            <Select value={selectedColumn1} onChange={handleColumn1Change}>
              {gridOptions.columnDefs.map((column) => (
                <MenuItem key={column.field} value={column.field}>
                  {column.headerName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={{ marginBottom: "10px" }}>
            {/* <InputLabel>Select Column 2</InputLabel> */}
            <Select value={selectedColumn2} onChange={handleColumn2Change}>
              {gridOptions.columnDefs.slice(0, 12).map((column) => (
                <MenuItem key={column.field} value={column.field}>
                  {column.headerName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <StoreFilter
            onFilterChange={applyExternalFilter}
            sectors={sectors}
            perceel={perceel}
            selectedData={sortedData}
            team={team}
            exportCSV={exportCSV}
            maxAfwijking={maxAfwijking}
            minAfwijking={minAfwijking}
            maxOppervlakte={maxOppervlakte}
            minOppervlakte={minOppervlakte}
            clearFilters={clearFilters}
          />
          {/* </Collapse> */}
        </div>
      </Drawer>
      {/* <Badge badgeContent={rowData.length} max={rowData.length +1} color="primary">
        <MailIcon color="action" />
      </Badge> */}
      <div
        className="ag-theme-alpine"
        style={{ height: "400px", width: "100%" }}
      >
        <AgGridReact
          onGridReady={onGridReady}
          rowData={rowData}
          gridOptions={gridOptions}
          animateRows={true}
          domLayout="autoHeight"
          onSortChanged={onSortChanged}
        />
      </div>
    </Box>
  );
}

export default DataDisplay;

//Filters verdwijnen als menu dichtgaat
// Clear filter toon lijst opnieuw.--> DONE
