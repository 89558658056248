import React, { useEffect, useState } from "react";
import { AgChartsReact } from "ag-charts-react";

const DataChart = ({ selectedData }) => {
  const [chartOptions, setChartOptions] = useState({});
  const chartData = selectedData || [];
  // console.log(selectedData)
  useEffect(() => {
    if (chartData.length > 0) {
      const firstItem = chartData[0];
      const keys = Object.keys(firstItem);

      // console.log(keys)
      if (keys.length >= 2) {
        const xKey = keys[0];
        const yKey = keys[1];
        const zKey = keys[2];

        const newChartOptionsLine = {
          //   container: "chart-container", // Zorg ervoor dat het container-ID correct is
          autoSize: true,
          data: chartData,
          theme: {
            overrides: {
              line: {
                series: {
                  highlightStyle: {
                    series: {
                      strokeWidth: 3,
                      dimOpacity: 0.2,
                    },
                  },
                },
              },
            },
          },
          series: [
            {
              type: "line",
              xKey: xKey,
              yKey: yKey,
              stroke: "#01c185",
              marker: {
                stroke: "#01c185",
                fill: "#fff",
              },
              strokeWidth: 2,
              tooltip: {
                enabled: true,
                renderer: (params) => {
                  return `${xKey}: ${
                    params.datum[xKey] + params.datum[zKey]
                  }\n${yKey}: ${params.datum[yKey]}`;
                },
              },
            },
          ],

          xAxis: {
            title: {
              text: xKey,
            },
            // label: {
            //   autoRotate: true,
            // },
          },
          yAxis: {
            title: {
              text: yKey,
            },
            // label: {
            //   autoRotate: true,
            // },
          },
          // legend: {
          //   enabled: false,
          // },
          navigator: {
            enabled: true,
          },
        };

        // setChartOptions(newChartOptionsBar);
        setChartOptions(newChartOptionsLine);
      }
    } else {
      // console.warn("No data available for chart.");
    }
  }, [chartData]);

  return (
    <div>
      <div id="chart-container" style={{ height: "400px", width: "100%" }}>
        <AgChartsReact options={chartOptions} />
      </div>
    </div>
  );
};

export default DataChart;

// export const setData = (selectedData) => {
//   // Process the selected data as needed in your DataChart component
//   console.log("setData function called with data:", selectedData);
// };
