import React, { useState } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import MenuIcon from "@mui/icons-material/Menu";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import DataDisplay from "./Building/ShowTableInformation";
const Main = () => {
  const { instance } = useMsal();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleLogin = () => {
    instance.loginRedirect(); // Initiates the login popup
  };
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const goToSharepoint = () => {
    window.open(
      "https://ingeniumnv.sharepoint.com/:x:/r/sites/Documenten/KIK/Corporate/13.%20Inspiratieplatformen/Platform%20digitaal/(BIG)%20DATA/OpmerkingenWebApps/Raming-GunningOpmerkingen.xlsx?d=wf55274e508f64660bb1b86e062ce40e4&csf=1&web=1&e=oclRdP",
      "_blank",
    ); // Replace with your YouTube link
  };
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon /> {/* MenuIcon here */}
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Raming - Gunning
          </Typography>
          <AuthenticatedTemplate>
            <IconButton
              color="inherit"
              aria-label="sharepoint"
              onClick={goToSharepoint}
            >
              <MicrosoftIcon /> {/* YouTube icon here */}
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Opmerkingen
              </Typography>
            </IconButton>
            {/* Content for authenticated users */}
            {/* <Button variant="contained" color="primary">
              Logged In
            </Button> */}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            {/* Content for unauthenticated users */}
            <Button variant="contained" color="primary" onClick={handleLogin}>
              Login
            </Button>
          </UnauthenticatedTemplate>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xxl" sx={{ mb: 3 }}>
        <Box mt={3}>
          <AuthenticatedTemplate>
            <div>
              <DataDisplay
                toggleDrawer={toggleDrawer}
                openDrawer={openDrawer}
              />
            </div>
          </AuthenticatedTemplate>
        </Box>
      </Container>
    </div>
  );
};

export default Main;
