// StoreFilter.js

import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  Select,
  InputLabel,
} from "@mui/material";

const StoreFilter = ({
  onFilterChange,
  sectors,
  perceel,
  selectedData,
  team,
  exportCSV,
  clearFilters,
}) => {
  //------------------------------------------------ CREATE UNIQUE ARRAY ------------------------------------------------

  const uniqueSectors = Array.from(new Set(sectors));
  const uniquePerceel = Array.from(new Set(perceel));
  const uniqueTeam = Array.from(new Set(team));

  //------------------------------------------------ DATUM RAMING ------------------------------------------------

  const [startDateRaming, setStartDateRaming] = useState("");
  const [endDateRaming, setEndDateRaming] = useState("");

  //------------------------------------------------ DATUM GUNNING ------------------------------------------------

  const [startDateGunning, setStartDateGunnig] = useState("");
  const [endDateGunning, setEndDateGunning] = useState("");

  //------------------------------------------------ DATUM NAZICHT ------------------------------------------------

  const [startDateNazicht, setStartDateNazicht] = useState("");
  const [endDateNazicht, setEndDateNazicht] = useState("");

  //------------------------------------------------ PROJECT CODE ------------------------------------------------

  const [searchQueryCode, setsearchQueryCode] = useState("");

  //------------------------------------------------ PROJECT DESCRIPTION ------------------------------------------------

  const [searchQueryDesc, setsearchQueryDesc] = useState("");

  //------------------------------------------------ SECTOR ------------------------------------------------

  const [selectedSector, setSelectedSector] = useState([]);

  //------------------------------------------------ PERCEEL ------------------------------------------------

  const [selectedPerceel, setSelectedPerceel] = useState([]);

  //------------------------------------------------ TEAM ------------------------------------------------

  const [selectedTeam, setSelectedTeam] = useState([]);

  //------------------------------------------------ OPPERVLAKTE ------------------------------------------------

  const [minOppervlakte, setMinOppervlakte] = useState("");
  const [maxOppervlakte, setMaxOppervlakte] = useState("");

  //------------------------------------------------ AFWIJKING ------------------------------------------------

  const [minAfwijking, setMinAfwijking] = useState("");
  const [maxAfwijking, setMaxAfwijking] = useState("");

  //------------------------------------------------ PRIJS RAMING ------------------------------------------------

  const [minRaming, setMinRaming] = useState("");
  const [maxRaming, setMaxRaming] = useState("");

  //------------------------------------------------ PRIJS GUNNING ------------------------------------------------

  const [minGunning, setMinGunning] = useState("");
  const [maxGunning, setMaxGunning] = useState("");

  //------------------------------------------------ PRIJS m² RAMING ------------------------------------------------

  const [minPrijsRaming, setMinPrijsRaming] = useState("");
  const [maxPrijsRaming, setMaxPrijsRaming] = useState("");

  //------------------------------------------------ PRIJS m² GUNNING ------------------------------------------------

  const [minPrijsGunning, setMinPrijsGunning] = useState("");
  const [maxPrijsGunning, setMaxPrijsGunning] = useState("");

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      handleFilterClick();
    }
  };

  const handleFilterClick = () => {
    const filters = {
      startDateRaming,
      startDateGunning,
      startDateNazicht,

      endDateRaming,
      endDateGunning,
      endDateNazicht,

      searchQueryCode,
      searchQueryDesc,

      selectedSector,
      selectedPerceel,
      selectedTeam,

      minOppervlakte,
      maxOppervlakte,

      minAfwijking,
      maxAfwijking,

      minRaming,
      maxRaming,
      minGunning,
      maxGunning,

      minPrijsRaming,
      maxPrijsRaming,
      minPrijsGunning,
      maxPrijsGunning,
    };

    onFilterChange({ ...filters });
    // console.log("Handle",filters)
  };

  const handleClearFilters = () => {
    setStartDateRaming("");
    setStartDateGunnig("");
    setStartDateNazicht("");

    setEndDateRaming("");
    setEndDateGunning("");
    setEndDateNazicht("");

    setsearchQueryCode("");
    setsearchQueryDesc("");

    setSelectedSector([]);
    setSelectedPerceel([]);
    setSelectedTeam([]);

    setMinOppervlakte("");
    setMaxOppervlakte("");

    setMinAfwijking("");
    setMaxAfwijking("");

    setMinRaming("");
    setMaxRaming("");

    setMinGunning("");
    setMaxGunning("");

    setMaxPrijsGunning("");
    setMinPrijsGunning("");

    setMaxPrijsRaming("");
    setMinPrijsRaming("");

    onFilterChange({});
    clearFilters();
  };

  return (
    <div>
      <Grid container spacing={2}>
        {/*------------------------------------------------ CODE SEARCH ------------------------------------------------*/}
        <Grid item xs={12} sm={12}>
          <InputLabel shrink> Search Project Code</InputLabel>

          <TextField
            // label="Search Project Code"
            // InputLabelProps={{ shrink: true }}

            fullWidth
            value={searchQueryCode}
            onChange={(e) => setsearchQueryCode(e.target.value)}
            onKeyPress={handleEnterKeyPress}
          />
        </Grid>

        {/*------------------------------------------------ DISCRIPTION SEARCH TODO ------------------------------------------------*/}

        <Grid item xs={12} sm={12}>
          <InputLabel shrink>Search Project description</InputLabel>

          <TextField
            // label="Search Project description"
            // InputLabelProps={{ shrink: true }}

            fullWidth
            value={searchQueryDesc}
            onChange={(e) => setsearchQueryDesc(e.target.value)}
            onKeyPress={handleEnterKeyPress}
          />
        </Grid>

        {/*------------------------------------------------ DROPDOWN SECTOR  TODO ------------------------------------------------*/}

        <Grid item xs={12} sm={12}>
          <InputLabel shrink>Sectoren</InputLabel>
          <Select
            label="Sectoren"
            multiple
            fullWidth
            value={selectedSector}
            onChange={(e) => setSelectedSector(e.target.value)}
          >
            {uniqueSectors.map((sector) => (
              <MenuItem key={sector} value={sector}>
                {sector}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/*------------------------------------------------ DROPDOWN PERCEEL ------------------------------------------------*/}

        <Grid item xs={12} sm={12}>
          <InputLabel shrink>Percelen</InputLabel>
          <Select
            label="percelen"
            multiple
            fullWidth
            value={selectedPerceel}
            onChange={(e) => setSelectedPerceel(e.target.value)}
          >
            {uniquePerceel.map((perceel) => (
              <MenuItem key={perceel} value={perceel}>
                {perceel}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/*------------------------------------------------ DROPDOWN TEAM ------------------------------------------------*/}

        <Grid item xs={12} sm={12}>
          <InputLabel shrink>Teams</InputLabel>
          <Select
            multiple
            fullWidth
            value={selectedTeam}
            onChange={(e) => setSelectedTeam(e.target.value)}
          >
            {uniqueTeam.map((team) => (
              <MenuItem key={team} value={team}>
                {team}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/*------------------------------------------------ SLIDER OPPERVLAKTE TODO max waarde ------------------------------------------------*/}

        <Grid item xs={12} sm={6}>
          <InputLabel shrink>Min Oppervlakte</InputLabel>
          <TextField
            type="number"
            fullWidth
            value={minOppervlakte}
            onChange={(e) => setMinOppervlakte(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel shrink>Max Oppervlakte</InputLabel>
          <TextField
            type="number"
            fullWidth
            value={maxOppervlakte}
            onChange={(e) => setMaxOppervlakte(e.target.value)}
          />
        </Grid>

        {/*------------------------------------------------ SLDIER AFWIJKING ------------------------------------------------*/}

        <Grid item xs={12} sm={6}>
          <InputLabel shrink>Min Afwijking</InputLabel>
          <TextField
            type="number"
            fullWidth
            value={minAfwijking}
            onChange={(e) => setMinAfwijking(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel shrink>Max Afwijking</InputLabel>
          <TextField
            type="number"
            fullWidth
            value={maxAfwijking}
            onChange={(e) => setMaxAfwijking(e.target.value)}
          />
        </Grid>

        {/*------------------------------------------------ DATUM RAMING ------------------------------------------------*/}

        <Grid item xs={12} sm={6}>
          <TextField
            label="Start Date Raming"
            InputLabelProps={{ shrink: true }}
            type="date"
            fullWidth
            value={startDateRaming}
            onChange={(e) => setStartDateRaming(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="End Date Raming"
            InputLabelProps={{ shrink: true }}
            type="date"
            fullWidth
            value={endDateRaming}
            onChange={(e) => setEndDateRaming(e.target.value)}
          />
        </Grid>

        {/*------------------------------------------------ DATUM GUNNING ------------------------------------------------*/}

        <Grid item xs={12} sm={6}>
          <TextField
            label="Start Date Gunning"
            InputLabelProps={{ shrink: true }}
            type="date"
            fullWidth
            value={startDateGunning}
            onChange={(e) => setStartDateGunnig(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="End Date Gunning"
            InputLabelProps={{ shrink: true }}
            type="date"
            fullWidth
            value={endDateGunning}
            onChange={(e) => setEndDateGunning(e.target.value)}
          />
        </Grid>

        {/*------------------------------------------------ DATUM NAZICHT ------------------------------------------------*/}

        <Grid item xs={12} sm={6}>
          <TextField
            label="Start Date Nazicht"
            InputLabelProps={{ shrink: true }}
            type="date"
            fullWidth
            value={startDateNazicht}
            onChange={(e) => setStartDateNazicht(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="End Date Nazicht"
            InputLabelProps={{ shrink: true }}
            type="date"
            fullWidth
            value={endDateNazicht}
            onChange={(e) => setEndDateNazicht(e.target.value)}
          />
        </Grid>

        {/*------------------------------------------------ PRIJS RAMING ------------------------------------------------*/}

        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            label="Min Raming"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={minRaming}
            onChange={(e) => setMinRaming(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            label="Max Raming"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={maxRaming}
            onChange={(e) => setMaxRaming(e.target.value)}
          />
        </Grid>

        {/*------------------------------------------------ PRIJS GUNNING ------------------------------------------------*/}

        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            label="Min Gunning"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={minGunning}
            onChange={(e) => setMinGunning(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            label="Max Gunning"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={maxGunning}
            onChange={(e) => setMaxGunning(e.target.value)}
          />
        </Grid>

        {/*------------------------------------------------ Empty ------------------------------------------------ */}

        {/* <Grid item xs={12} sm={4}></Grid> */}
        {/*------------------------------------------------ PRIJS RAMING m² ------------------------------------------------*/}

        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            label="Min Prijst/m² Raming"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={minPrijsRaming}
            onChange={(e) => setMinPrijsRaming(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            label="Max Prijst/m² Raming"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={maxPrijsRaming}
            onChange={(e) => setMaxPrijsRaming(e.target.value)}
          />
        </Grid>

        {/*------------------------------------------------ PRIJS GUNNING m² ------------------------------------------------*/}

        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            label="Min Prijst/m² Gunning"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={minPrijsGunning}
            onChange={(e) => setMinPrijsGunning(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            label="Max Prijst/m² Gunning"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={maxPrijsGunning}
            onChange={(e) => setMaxPrijsGunning(e.target.value)}
          />
        </Grid>
        {/*------------------------------------------------ Empty ------------------------------------------------ */}

        {/* <Grid item xs={12} sm={4}></Grid> */}

        {/*------------------------------------------------ APPLY FILTER ------------------------------------------------*/}

        <Grid item xs={12} sm={12}>
          <Button
            onClick={handleFilterClick}
            variant="contained"
            color="primary"
            fullWidth
          >
            Apply Filter + Graph
          </Button>
        </Grid>

        {/*------------------------------------------------ APPLY CLEAR ------------------------------------------------*/}

        <Grid item xs={12} sm={12}>
          <Button
            onClick={handleClearFilters}
            variant="contained"
            color="error"
            fullWidth
          >
            Clear Filters
          </Button>
        </Grid>

        {/*------------------------------------------------ APPLY EXPORT CSV ------------------------------------------------*/}

        <Grid item xs={12} sm={12}>
          <Button
            onClick={() => {
              exportCSV(); // Roep de exportCSV functie aan wanneer de knop wordt ingedrukt
            }}
            variant="contained"
            color="success"
            fullWidth
          >
            ExportCSV
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default StoreFilter;
